html {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Roboto", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  text-rendering: optimizeLegibility;
}

#root {
  height: 100%;
}